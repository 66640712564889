// BOOTSTRAP 4 VARIABLES

@import 'components/bootstrap4/functions';
@import 'components/bootstrap4/variables';
@import 'components/bootstrap4/mixins';

// ArchitectUI Themes Variables

@import 'themes/layout-variables';

// BOOTSTRAP 4 IMPORTS

@import 'components/bootstrap4/root';
@import 'components/bootstrap4/reboot';
@import 'components/bootstrap4/type';
@import 'components/bootstrap4/images';
@import 'components/bootstrap4/code';
@import 'components/bootstrap4/grid';
@import 'components/bootstrap4/tables';
@import 'components/bootstrap4/forms';
@import 'components/bootstrap4/buttons';
@import 'components/bootstrap4/transitions';
@import 'components/bootstrap4/dropdown';
@import 'components/bootstrap4/button-group';
@import 'components/bootstrap4/input-group';
@import 'components/bootstrap4/custom-forms';
@import 'components/bootstrap4/nav';
@import 'components/bootstrap4/navbar';
@import 'components/bootstrap4/card';
@import 'components/bootstrap4/breadcrumb';
@import 'components/bootstrap4/pagination';
@import 'components/bootstrap4/badge';
@import 'components/bootstrap4/jumbotron';
@import 'components/bootstrap4/alert';
@import 'components/bootstrap4/progress';
@import 'components/bootstrap4/media';
@import 'components/bootstrap4/list-group';
@import 'components/bootstrap4/close';
@import 'components/bootstrap4/toasts';
@import 'components/bootstrap4/modal';
@import 'components/bootstrap4/tooltip';
// @import "~bootstrap/scss/popover"; // BOOTSTRAP 5.1.3
@import 'components/bootstrap4/popover'; // VAMO VE
@import 'components/bootstrap4/carousel';
@import 'components/bootstrap4/spinners';
@import 'components/bootstrap4/utilities';
@import 'components/bootstrap4/print';
// LAYOUT

@import 'layout/layout';

// UTILS

@import 'utils/helpers';
@import 'utils/backgrounds';
@import 'utils/animate';
@import 'utils/comps-animations';

// ELEMENTS

@import 'elements/buttons';
@import 'elements/dropdown';
@import 'elements/navs';
@import 'elements/badges';
@import 'elements/cards';
@import 'elements/tabs';
@import 'elements/accordions';
@import 'elements/modals';
@import 'elements/loaders';
@import 'elements/progressbar';
@import 'elements/timeline';
@import 'elements/listgroup';
@import 'elements/forms';
@import 'elements/pagination';
@import 'elements/chat';
@import 'elements/forms-wizard';

// DASHBOARD BOXES

@import 'widgets/chart-boxes/chart-boxes';
@import 'widgets/content-boxes/content-boxes';
@import 'widgets/profile-boxes/profile-boxes';

// PAGES

@import 'pages/userpages';

// Applications

@import 'applications/applications-base';

// COMPONENTS

// Perfect Scrollbar
@import 'components/perfect-scrollbar/perfect-scrollbar';

// RC Tabs
@import 'components/rc-tabs/rc-tabs';

// Loading Buttons
@import 'components/loading-buttons/loading-buttons';

// Hamburger Button
@import 'components/hamburgers/hamburgers';

// Drawer
@import 'components/drawer/drawer';

// Notifications
@import 'components/notifications/sweetalerts';
@import 'components/notifications/toastify';

// Modals
@import 'components/modals/rodal';

// Progress Bar
@import 'components/progress-bar/progress-bar';

// Slick Carousel
@import 'components/slick-carousel/slick/slick';

// Block loading
@import 'components/block-loading/block-loading';

// Popovers & Tooltips
@import 'components/popovers-tooltips/popovers-tooltips';

// Sticky Elements
@import 'components/sticky-elements/sticky-elements';

// Calendar
@import 'components/calendar/calendar';

// Splitters
@import 'components/splitters/splitters';

// Tree View
@import 'components/tree-view/tree-view';

// Tables
@import 'components/tables/tables';

// Icons
@import 'components/icons/linearicons';
@import 'components/icons/p7icons';

// FORMS

@import 'components/forms/datepicker/datepicker';
@import 'components/forms/rangeslider/rangeslider';
@import 'components/forms/multiselect/multiselect';
@import 'components/forms/wysiwyg/wysiwyg';
@import 'components/forms/toggle-switch/switch';
@import 'components/forms/dropzone/dropzone';
@import 'components/forms/typeahead/typeahead';

// REACT WIDGETS

@import 'components/react-widgets/scss/react-widgets';

// DEMO

@import 'demo-ui/demo';

// Responsive

@import 'layout/responsive/responsive-base';

// Fonts
@import 'fonts/index';

// SCROLLBAR
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-radius: 100px;
}

.box-totais {
  scrollbar-width: 10px;
}

* {
  font-family: Roboto;
  font-weight: lighter;
}

.circulo {
  width: 32px;
  height: 32px;

  background-color: white;

  border-radius: 50%;
  border-style: solid;
  border-color: #00abde;
  border-width: 1px;

  margin-bottom: -27px;
  margin-left: auto;
  margin-right: auto;
}

.linhaWizard {
  width: 1px;
  height: 98%;
  background-color: #00abde;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.spinner {
  border: 3px solid rgba(0, 171, 222, 0.8);
  border-left-color: #29d694;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#root {
  background: #f1f4f6;
}

.cursor-pointer {
  cursor: pointer;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  border: 2px solid #fff;
  background-color: #E75190;
  color: #fff;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pin span {
  position: absolute;
  font-weight: bold;
  transform: rotate(45deg);
  left: 28%;
  top: 30%;
  bottom: 65%;
}

.pulse {
  background: #E75190;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
  &:after {
    content: '';
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #E75190;
    animation-delay: 1.1s;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.react-html5-camera-photo {
  position: inherit !important;

  #container-circles {
    position: absolute !important;
    left: 50% !important;
    bottom: 85px !important;
    cursor: pointer !important;

    #outer-circle {
      background-color: var(--amareloFolha) !important;
      cursor: pointer !important;

      #inner-circle {
        background: none !important;
        border: solid 1px white !important;
        height: 60px !important;
        width: 60px !important;
        margin: -30px 0 0 -30px !important;
        cursor: pointer !important;
      }
    }
  }

  #display-error {
    width: 550px !important;
    @media only screen and (max-width: 992px) {
      width: 100% !important;
      height: 380px !important;
      object-fit: cover;
    }
  }
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  width: 550px !important;
  border-radius: 1% !important;

  @media only screen and (max-width: 992px) {
    width: 100% !important;
    height: 380px !important;
    object-fit: cover;
  }
}

.sub-title {
  letter-spacing: 1.76px;
  font-size: 11px;
  color: var(--secondary);
}

.wizard-title {
  font-size: 15px;
  letter-spacing: 1.76px;
  color: var(--secondary);
}

.letter-spacing-folha {
  letter-spacing: 1.76px;
}

.cursor-hover {
  cursor: pointer;
}

.DayPicker-Day--disabled {
  pointer-events: none;
}

@mixin size($width: inherit, $height: inherit) {
  width: $width;
  height: $height;
}

@mixin flex($vertical, $horizontal, $direction: row) {
  display: flex;
  align-items: $vertical;
  justify-content: $horizontal;
  flex-direction: $direction;
}

@mixin border-radius(
  $topLeft: 50%,
  $topRight: 50%,
  $botLeft: 50%,
  $botRight: 50%
) {
  border-top-left-radius: $topLeft;
  border-top-right-radius: $topRight;
  border-bottom-left-radius: $botLeft;
  border-bottom-right-radius: $botRight;
}

$deepBlue-5: #f1f3f4;
$deepBlue-10: #e4e6ea;
$deepBlue-20: #ccced5;
$deepBlue-40: #999eac;
$deepBlue-60: #666d82;
$deepBlue-80: #333d59;
$deepBlue-90: #192445;
$deepBlue-100: #000c2f;
$deepBlue-120: #000923;

$blue: #2e90eb;
$salmon: #f56b72;

$swipes-shadow-one: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
$swipes-shadow-two: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$swipes-shadow-three: 0 10px 20px rgba(0, 0, 0, 0.19),
  0 6px 6px rgba(0, 0, 0, 0.23);
$swipes-shadow-four: 0 14px 28px rgba(0, 0, 0, 0.25),
  0 10px 10px rgba(0, 0, 0, 0.22);
$swipes-shadow-five: 0 19px 38px rgba(0, 0, 0, 0.3),
  0 15px 12px rgba(0, 0, 0, 0.22);

.sw-loader__wrapper {
  position: relative;

  &--center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .sw-loader__holder {
    @include size(70%, 70%);
    margin-left: 15%;
    margin-top: 15%;
    position: relative;
    transform: rotate(-45deg);

    .rect {
      @include size(100%, 100%);
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(to right, #00487e, #8600c1, #8600c1, #00487e);
      background-position: 0% 50%;
      background-size: 1000% 1000%;
      overflow: hidden;
      animation: moveGradient 5s infinite;
    }
  }
}

@keyframes moveGradient {
  to {
    background-position: 100% 50%;
  }
}

.dropdown-menu.dropdown-menu-sm {
  min-width: 11rem !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
  padding: 0 !important;
}

.MuiAccordionSummary-content .Mui-expanded {
  margin: 0 !important;
}

.modal-transparent {
  background-color: transparent !important;
}